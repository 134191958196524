import WhiteInput from '../../../../UI/inputs/WhiteInput/WhiteInput'
import FullButton from '../../../../UI/buttons/FullButtons/FullButton'
import Fb from '../../../../../../public/img/login-group/fb.svg?inline'
import ForgotComplete from './ForgotComplete/ForgotComplete.vue'
import BottomLine from "../../../../coreComponents/BottomLine/BottomLine.vue";


import {validation} from "../../../../../services/validation";
import TooltipBtn from "../../../../UI/tooltips/TooltipBtn/TooltipBtn";
import PasswordRequirements from "@/components/coreComponents/PasswordRequirements/PasswordRequirements";
var _ = require('lodash');




export default {
  name: "ForgotReset",

  components: {
    WhiteInput,
    Fb,
    FullButton,
    BottomLine,
    ForgotComplete,
    TooltipBtn,
    PasswordRequirements,
  },

  data() {
    return{
      errorForm: false,
      forgotComplete: false,
      password: '',
      serverError: false,
      visibleValidation: false,

      validation: {
        password: false
      },

      validationTranslate: {
        password: ''
      },

      validationTxt: {
        password: false
      },
    }
  },

  mounted() {
    if(window.location.href.indexOf('?') > -1){
      let data = this.getPropsFromHash();

      this.$store.dispatch('checkResetPass', data).then((response) => {
        if(_.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
          console.log(response);
        } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          // this.$router.push(this.$store.getters.GET_PATHS.forgot);
        }
      });

    } else {
      this.$router.push(this.$store.getters.GET_PATHS.forgot);
    }
  },

  methods: {
    forgotResetSubmit() {
      this.visibleValidation = true

      let validationItems = {
        password: this.password,
      }

      let validationOptions = {
        password: {
          type: [
            'password',
            'empty',
          ]
        },

      }

      let validate = validation(validationItems, validationOptions);
      this.validation = validate.validation;
      this.validationTranslate = validate.validationError;

      if(validate.isValidate){
        this.serverError = false;

        let data = this.getPropsFromHash();
        data.password = validationItems.password;

        this.$store.dispatch('resetPass', data).then((response) => {

          if(_.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){
            this.$router.push(this.$store.getters.GET_PATHS.auth);
          } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
            let errors = response.response.data.errors;

            this.serverError = true;

            errors.password ? ( this.validation.password = true, this.validationTxt.password = errors.password[0] ) : false;
          }

        });

      }
    },

    getPropsFromHash() {
      var url = new URL(window.location.href);
      var token = url.searchParams.get("token");
      var mail = url.searchParams.get("email");

      return {
        token: token,
        email: mail,
      };
    }

  }

}
